.dialogModal {
  text-align: center;

  @mixin tvDevice {
    width: 100%;
  }

  &__title {
    margin: 0 0 1rem;
    font-family: var(--font_canal);
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: var(--color_white);
    text-transform: uppercase;

    @mixin tvDevice {
      margin-bottom: 4.75rem;
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  }

  &__description {
    margin: 0.375rem auto 1.25rem;
    font-family: var(--font_hind);
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--color_white);

    @mixin tvDevice {
      margin: 0 auto 2rem;
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }

  &__options {
    li:not(:last-child) {
      margin-bottom: 0.5rem;
      @mixin tvDevice {
        margin-bottom: 0.9375rem;
      }
    }

    li {
      display: flex;
      justify-content: center;
    }
  }
}
