.callbackModal {
  --callbackModal_title_margin_bottom: 0.5rem;
  --callbackModal_title_fontSize: 1.125rem;
  --callbackModal_subtitle_fontSize: 1rem;
  --callbackModal_subtitle_margin_bottom: 5.0625rem;
  --callbackModal_padding: 2rem 1.625rem;

  @media (--mq-from-laptop) {
    --callbackModal_title_margin_bottom: 1rem;
    --callbackModal_title_fontSize: 1.25rem;
    --callbackModal_subtitle_margin_bottom: 3.1875rem;
  }

  @mixin tvDevice {
    --callbackModal_title_margin_bottom: 1.5rem;
    --callbackModal_title_fontSize: 2.25rem;
    --callbackModal_subtitle_fontSize: 1.875rem;
    --callbackModal_subtitle_margin_bottom: 3rem;
    --callbackModal_padding: 4.75rem 3.75rem 6.5rem;
  }

  .callbackModal__body {
    padding: var(--callbackModal_padding);
  }

  &__title {
    margin-bottom: var(--callbackModal_title_margin_bottom);
    font-family: var(--font_canal);
    font-size: var(--callbackModal_title_fontSize);
    text-transform: uppercase;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: var(--callbackModal_subtitle_margin_bottom);
    font-size: var(--callbackModal_subtitle_fontSize);
    color: var(--color_white-70);
  }

  &__button {
    margin-top: auto;
  }
}
