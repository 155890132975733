.downloadBtn {
  width: 100%;

  &__text {
    margin-left: 1.1875rem;
    font-size: 1.75rem;
  }
}

.D2GModal {
  margin: auto 1rem 3.5rem;

  @media (--mq-from-tablet) {
    max-width: 36.375rem;
    margin: auto;
  }
}
